var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"kZTq7LrmFMvK2R2Yb7hJj"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData,
  HttpClient
} from '@sentry/integrations';

import { Dedupe, _shouldDropEvent } from '@sentry/integrations/cjs/dedupe';

/**
 * Have to extend Sentry's Dedupe. So we can stop this process if window load event has not fired.
 * https://github.com/getsentry/sentry-javascript/blob/develop/packages/integrations/src/dedupe.ts
 * */
export class DedupeIntegration extends Dedupe {
  constructor(environment) {
    super();

    this._isLoaded = false;

    if (environment) {
      environment.addEventListener('load', () => {
        this._isLoaded = true;
      });
    }
  }

  /**
   * Only change from Sentry's implementation is checking if window load has been fired.
   * @param {Function} addGlobalEventProcessor
   * @param {Function} getCurrentHub
   */
  setupOnce(addGlobalEventProcessor, getCurrentHub) {
    const eventProcessor = (currentEvent) => {
      // We want to ignore any non-error type events, e.g. transactions or replays
      // These should never be dedupe, and also not be compared against as _previousEvent.
      // I added _isLoaded check to ensure they will not be dropped once window load event has been fired.
      if (currentEvent.type || !this._isLoaded) {
        return currentEvent;
      }

      const self = getCurrentHub().getIntegration(Dedupe);

      if (self) {
        // Just in case something goes wrong
        try {
          if (_shouldDropEvent(currentEvent, self._previousEvent)) {
            __DEBUG_BUILD__ &&
              logger.warn(
                'Event dropped due to being a duplicate of previously captured event.'
              );

            return null;
          }
        } catch (_oO) {
          return (self._previousEvent = currentEvent);
        }

        return (self._previousEvent = currentEvent);
      }

      return currentEvent;
    };

    eventProcessor.id = this.name;
    addGlobalEventProcessor(eventProcessor);
  }
}

/*
  This array contains the generic errors thrown by browsers (Safari, Chrome and Firefox)
  when a fetch call is cancelled. We've added this array due to the number of errors
  being logged when a freshrelevance fetch call is cancelled. Don't just add to this array,
  investigate whether the cancelled call is a bug that can be fixed first. See Jira ticket WD-7159
  for more information about this fix.
*/
const fetchCancelledErrors = [
  /^TypeError: Load failed/,
  /^TypeError: cancelled/,
  /^TypeError: Failed to fetch/,
  /^TypeError: NetworkError when attempting to fetch resource./
];

const denyGoogleTranslateRegex = new RegExp(/\.translate\.goog/);

const eventQueue: any[] = [];

window.isLoaded = false;
window.addEventListener('load', () => {
  window.isLoaded = true;
  let item;

  while ((item = eventQueue.shift())) {
    // I have no idea why we must do this, Sentry does not ignore the event.
    delete item.event.event_id;

    if (item.hint.originalException instanceof Error) {
      Sentry.captureEvent(item.event, item.hint);
    } else {
      // This is not a perfect solution.
      // We must do this so Sentry will submit `console.error`. With a stack trace of the error.
      Sentry.captureException(
        new Error(item.event.message, { cause: item.hint.syntheticException })
      );
    }
  }
});

/**
 * Don't change the order as it matches browser's user agent from top to bottom
 * Browser and minimum version support: https://foxtons.atlassian.net/wiki/spaces/WDOC/pages/2157740033/Browser+Support
 */
const BROWSER_SUPPORT_LIST = [
  // Safari iOS: check first as all iOS browsers use Safari engine underneath, return iOS version
  {
    minVersion: 14,
    pattern: /(\d+)(_\d+)* like Mac OS X/i
  },
  // Edge has "chrome" and "safari" in user agent, check before chrome and safari
  {
    minVersion: 88,
    pattern: /edg.*\/(\d+)/i
  },
  // Opera has "chrome" and "safari" in user agent, check before chrome and safari
  {
    minVersion: 73,
    pattern: /opr\/(\d+)/i
  },
  // Samsung Browser has "chrome" and "safari" in user agent, check before chrome and safari
  {
    minVersion: 14,
    pattern: /SamsungBrowser\/(\d+)/i
  },
  // Chrome Android has "safari" in user agent, check before safari
  {
    minVersion: 88,
    pattern: /chrome\/(\d+).*mobile/i
  },
  // Chrome has "safari" in user agent, check before safari
  {
    minVersion: 88,
    pattern: /chrome\/(\d+)/i
  },
  // Firefox Android
  {
    minVersion: 84,
    pattern: /mobile.*firefox\/(\d+)/i
  },
  // Firefox
  {
    minVersion: 84,
    pattern: /firefox\/(\d+)/i
  },
  // Safari appears in most of browsers user agent except Firefox, check OS as it only supports Mac OS and iOS. iOS is checked previously
  {
    minVersion: 14,
    pattern: /Mac OS X.*version\/(\d+).*safari/i
  }
];

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_WEB_DSN,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  enabled:
    process.env.NEXT_PUBLIC_ENV !== 'local' &&
    process.env.NEXT_PUBLIC_ENV !== 'dev',

  // { keepalive: true } will prevent navigation errors being cancelled by browser, when user leaves the page.
  // but some requests will remain in pending forever if the total size of all parallel requests > 64k.
  // so set to false to disable the feature
  // https://github.com/getsentry/sentry-javascript/issues/6049
  transportOptions: { fetchOptions: { keepalive: false } },
  allowUrls: [
    /https?:\/\/[^\/]*.foxtons.co.uk/,
    /https?:\/\/[^\/]*.foxtons-cloud.link/
  ],
  tracePropagationTargets: [
    /https?:\/\/[^\/]*.foxtons.co.uk/,
    /https?:\/\/[^\/]*.foxtons-cloud.link/
  ],
  beforeSend(event, hint) {
    const userAgent = window.navigator.userAgent;
    const browser = BROWSER_SUPPORT_LIST.find((it) =>
      userAgent.match(it.pattern)
    );

    // skip reporting if browser is supported but version is before min supported version

    if (browser) {
      const version = parseInt(userAgent.match(browser.pattern)[1], 10);

      if (version < browser.minVersion) {
        return null;
      }
    }

    // We don't have the mandate to fix issues occurring from viewing the website
    // through another domain (Google translate), ignore these errors.
    if (denyGoogleTranslateRegex.test(window.location.host)) {
      return null;
    }

    // We only send errors if the page has loaded successfully.
    if (!window.isLoaded) {
      // Due how Sentry works. Errors at this point are considered captured.
      // We must reset it otherwise it will not be sent when on load fires.
      // https://github.com/getsentry/sentry-javascript/issues/4525
      if (hint.originalException instanceof Error) {
        hint.originalException.__sentry_captured__ = false;
      }

      eventQueue.push({ event, hint });

      return null;
    }

    return event;
  },
  environment: process.env.NEXT_PUBLIC_ENV,
  integrations: [
    new CaptureConsoleIntegration({ levels: ['error'] }),
    new ExtraErrorData(),
    new DedupeIntegration(window),
    new HttpClient()
  ],
  ignoreErrors: [
    /^InvalidStateError: Document is not fully active/,
    /^SecurityError: Blocked a frame with origin "https:\/\/www\.foxtons\.co\.uk" from accessing a cross-origin frame/,
    /^ResizeObserver loop limit exceeded/,
    /^Non-Error promise rejection captured/,
    /^SyntaxError: Block-scoped declarations \(let, const, function, class\) not yet supported outside strict mode/,
    /^XDEFI/, // Ignore errors originating from this chrome extension: https://chrome.google.com/webstore/detail/xdefi-wallet/hmeobnfnfcmdkdcmlblgagmfpfboieaf/related?hl=en
    /^ReferenceError: Can't find variable: msDiscoverChatAvailable/, // https://github.com/getsentry/sentry-javascript/issues/8444
    ...fetchCancelledErrors
  ]
});
